//
import PCategoryService from '@/services/api/Provider/PCategory'
import RulesService from '@/services/rules'

//
export default {
  name: 'v-view-account-categories',
  data() {
    return {
      busy: {
        create: false,
        delete: false,
        listed: false,
        update: false,   
      },
      dialogs: {
        create: false,
        delete: false,
        update: false,
      },
      filters: {
        limit: 5,
        page: 1,
      },
      pCategoryCreate: {},
      pCategoryDelete: {},
      pCategoryStored: {},
      pCategoryUpdate: {},
      pCategories: {
        data: [],
        last_page: 1,
      },
    }
  },
  methods: {

    /**
     * 
     */
    handleCreateReset() {
      this.pCategoryCreate = PCategoryService.toCreate()
    },

    /**
     * 
     */
    async handleCreateSubmit() {
      this.busy.create = true
      await PCategoryService.create(this.pCategoryCreate).then(this.handleCreateSuccess).catch(this.handleError)
      this.busy.create = false
    },

    /**
     * 
     */
    handleCreateSuccess(payload) {
      this.handleReset()
      this.handleCreateReset()
      PCategoryService.translate({ response: payload }).then(this.$toast.success)
      this.dialogs.create  = false
    },

    /**
     * 
     */
    handleDeleteClick(pCategory) {
      this.dialogs.delete   = true
      this.pCategoryDelete  = pCategory
    },

    /**
     * 
     */
    async handleDeleteSubmit() {
      this.busy.delete = true
      await PCategoryService.delete(this.pCategoryDelete).then(this.handleDeleteSuccess).catch(this.handleError)
      this.busy.delete = false
    },

    /**
     * 
     */
    handleDeleteSuccess() {
      this.handleReset()
      this.dialogs.delete = false
    },

    /**
     * 
     */
    async handleReset() {
      this.busy.listed = true
      this.pCategories = await PCategoryService.getBy(this.filters)
      this.busy.listed = false
    },

    /**
     * 
     */
    handleUpdateClick(pCategory) {
      this.dialogs.update   = true
      this.pCategoryUpdate  = { ...pCategory }
    },

    /**
     * 
     */
    handleUpdateReset() {
      this.pCategoryUpdate  = { ...this.pCategoryStored }
    },

    /**
     * 
     */
    async handleUpdateSubmit() {
      this.busy.update = true
      await PCategoryService.update(this.pCategoryUpdate).then(this.handleUpdateSuccess).catch(this.handleError)
      this.dialogs.update   = false
      this.busy.update = false
    },

    /**
     * 
     */
    handleUpdateSuccess(payload) {
      PCategoryService.translate({ response: payload }).then(this.$toast.success)
      this.handleReset()
    },

    /**
     * 
     */
    handleError(payload) {
      PCategoryService.translate(payload).then(this.$toast.error)
    },
  },
  created() {
    this.handleReset()
  },
  props: {

    /**
     * 
     */
    fileRules: {
      type: Array,
      default: () => [
        RulesService.required,
      ],
    },

    /**
     * 
     */
    nameRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.max(200),
      ],
    },
  },
  watch: {

    /**
     * 
     */
    'filters.page'() {
      this.handleReset()
    }
  },
}